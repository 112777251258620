import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'; // Import Table
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import './Product.css';

function ProductDetails(props) {
  const { product, asin } = props;

  const associateID = 'findfast0a-20'; // Replace with your actual Amazon Associates ID

  const constructAmazonLink = (asin) => {
    return `http://www.amazon.com/dp/${asin}/ref=nosim?tag=${associateID}`;
  };

  const findVariantLink = () => {
    if (!asin) {
      return [constructAmazonLink(product.asin), product.main_image.link];
    }

    for (const variant of product.variants) {
      if (variant.asin === asin) {
        // Check if variant.image_url is empty, and return product.main_image.link if true
        const imageUrl =
          variant.image_url && variant.image_url.trim() !== ''
            ? variant.image_url
            : product.main_image.link;
        return [constructAmazonLink(variant.asin), imageUrl];
      }
    }

    return [constructAmazonLink(product.asin), product.main_image.link]; // Fallback if no variants match
  };

  const [variantLink, imageLink] = findVariantLink();

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1; // Default to page 1 if no page is specified
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage, // Ensure page is included in the query parameters
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  // Function to get the corresponding color based on value
  const getColor = (value) => {
    switch (value) {
      case 'Positive':
        return 'green';
      case 'Mixed':
        return 'orange';
      case 'Negative':
        return 'red';
      default:
        return 'black';
    }
  };

  const skip_rows = [
    'Department',
    'ASIN',
    'Best Sellers Rank',
    'size',
    'color',
    'Country of Origin',
  ]; // Replace with actual spec names to skip

  // Define consistent column width styles
  const columnStyle = { width: '50%' };

  return (
    <Card
      className="custom-card"
      style={{ maxWidth: '500px', margin: '0 auto' }}
    >
      <a href={variantLink} target="_blank" rel="noopener noreferrer">
        <img
          src={imageLink}
          className="card-img-top"
          alt={product.name}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectFit: 'scale-down',
          }}
        />
      </a>
      <Card.Body>
        <a href={variantLink} target="_blank" rel="noopener noreferrer">
          <Card.Title
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              height: '96px',
            }}
          >
            {product.title}
          </Card.Title>
        </a>
        <Rating rating={product.rating} numReviews={product.ratings_total} />
        {product.buybox_winner.price ? (
          <Card.Text>
            ${product.buybox_winner.price.value}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}

        {/* Add a table for customer feedback */}
        {product.customers_say && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Attribute</th>
                <th style={columnStyle}>Customer Opinion</th>
              </tr>
            </thead>
            <tbody>
              {product.customers_say.map((feedback, index) => (
                <tr key={index}>
                  <td style={columnStyle}>{feedback.name}</td>
                  <td
                    style={{ color: getColor(feedback.value), ...columnStyle }}
                  >
                    {feedback.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* Add a table for specs */}
        {product.specifications_wo_map && (
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th style={columnStyle}>Specification</th>
                <th style={columnStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={columnStyle}>Brand</td>
                <td style={columnStyle}>{product.brand}</td>
              </tr>
              {product.specifications_wo_map
                .filter((spec) => !skip_rows.includes(spec.name)) // Filter out rows to skip
                .map((spec, index) => (
                  <tr key={index}>
                    <td style={columnStyle}>{spec.name}</td>
                    <td style={columnStyle}>{spec.value}</td>
                  </tr>
                ))}
              <tr>
                <td style={columnStyle}>Date first available</td>
                <td style={columnStyle}>
                  {new Date(
                    product.date_first_available * 1000
                  ).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProductDetails;
