import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Rating from './Rating';
import axios from 'axios';
import { useContext } from 'react';
import './Product.css';

function Product(props) {
  const { product, selectedFilters } = props;
  const navigate = useNavigate(); // Initialize useNavigate

  const constructProductLink = (asin) => {
    return `/product/${asin}`; // Use relative path within your app
  };

  const findVariantLink = () => {
    if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
      return [constructProductLink(product.asin), product.main_image.link];
    }

    const variantFilters = Object.entries(selectedFilters).filter(([key, _]) =>
      key.startsWith('variants.')
    );
    if (variantFilters.length === 0) {
      return [constructProductLink(product.asin), product.main_image.link];
    }

    for (const variant of product.variants) {
      let matchesAll = variantFilters.every(([filterKey, filterValues]) => {
        const property = filterKey.split('.')[1]; // splits "variants.color" into ["variants", "color"]
        return filterValues.includes(variant[property]);
      });
      if (matchesAll) {
        const imageUrl =
          variant.image_url && variant.image_url.trim() !== ''
            ? variant.image_url
            : product.main_image.link;
        return [constructProductLink(variant.asin), imageUrl];
      }
    }

    return [constructProductLink(product.asin), product.main_image.link]; // Fallback if no variants match
  };

  const [variantLink, imageLink] = findVariantLink();

  const getFilterUrl = (filter) => {
    const filterPage = filter.page || 1; // Default to page 1 if no page is specified
    let queryParams = new URLSearchParams({
      ...filter,
      page: filterPage, // Ensure page is included in the query parameters
      order: 'bestseller',
    });

    return `/search?${queryParams.toString()}`;
  };

  const handleNavigation = (link) => {
    navigate(link); // Use navigate function to change route
  };

  return (
    <Card className="custom-card">
      <div
        onClick={() => handleNavigation(variantLink)}
        style={{ cursor: 'pointer' }}
      >
        <img
          src={imageLink}
          className="card-img-top"
          alt={product.name}
          style={{
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectFit: 'scale-down',
          }}
        />
      </div>
      <Card.Body>
        <Card.Title
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            height: '96px',
            cursor: 'pointer',
          }}
          onClick={() => handleNavigation(variantLink)}
        >
          {product.title}
        </Card.Title>
        <Rating rating={product.rating} numReviews={product.ratings_total} />
        {product.buybox_winner.price ? (
          <Card.Text>
            ${product.buybox_winner.price.value}
            <br />
            <span style={{ fontSize: 'small', color: 'grey' }}>
              as of{' '}
              {new Date(product.meas_timestamp * 1000).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </Card.Text>
        ) : (
          <Card.Text style={{ visibility: 'hidden' }}>$0.00</Card.Text>
        )}

        {product.bestsellers_rank &&
          Array.isArray(product.bestsellers_rank) && (
            <ul className="no-bullet-list">
              {(() => {
                const lowestRank = product.bestsellers_rank.reduce(
                  (min, rankInfo) =>
                    rankInfo.rank < min.rank ? rankInfo : min,
                  product.bestsellers_rank[0]
                );
                return (
                  <li key={lowestRank.category}>
                    Sales Rank #{lowestRank.rank} in{' '}
                    <a
                      href={getFilterUrl({
                        categories_flat: lowestRank.categories_flat,
                      })}
                    >
                      {lowestRank.category}
                    </a>
                  </li>
                );
              })()}
            </ul>
          )}
      </Card.Body>
    </Card>
  );
}
export default Product;
